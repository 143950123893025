import React from 'react';
import axios from 'axios';
import img from '../img/slika1.jpg'
import {Link} from 'react-router-dom'

export default class PoticajneKarte extends React.Component {
    state = {
        cards: [],
        error: null,
        cards1: [],
        cards2: [],
        vijesti: [],
        oneNewsTitle: [],
        oneNewsText: [],
        id: [],
        zadnjeTrI: [],
    };

    componentDidMount = async () => {
        try {
            const response1 = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=3');
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/7');
            const res = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/93');
            const res1 = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/96');
            this.setState({
                cards: response.data,
                vijesti: response1.data,
                cards1: res.data,
                cards2: res1.data,
            });
        } catch (error) {
            this.setState({ error });
        }
        this.setOneNews();
        this.lastThreeNews();
    };

    setOneNews = async () => {
        try {
            const responseOneNews = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/110');
            this.setState({
                oneNewsTitle: responseOneNews.data.title,
                oneNewsText: responseOneNews.data.content,
            });
        } catch (error) {
            this.setState({ error });
        }
    }
    lastThreeNews = async () => {
        for(var i = 0; i < 3; i++){
            this.state.zadnjeTrI.push(this.state.vijesti[i]);
        }
    }
    openPot = (id) => {
        this.props.history.push("/jednaKarta/" + id)
    }
    

    openCard1 = (id) => {
        this.props.history.push("/jednaVijest/" + id)
    }
    openNews = () => {
        this.props.history.push("/vijesti")
    }
    render() {
    
        const { error } = this.state;
        if (error) {
            return <div>An error occured: {error.message}</div>;
        }
        return (
            <div className="main-div">
                <div className="container-fluid cards-header">
                    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div className="carousel-item active">
                                <img className="slika-naslovna" onClick={() => this.openPot(this.state.cards.id)} src={"https://www.savezbezimena.eu/wp-content/uploads/2021/07/KARTA_1_6d50cc86d0.jpg"} alt="slika" />
                            </div>
                            <div className="carousel-item">
                                <img className="slika-naslovna" onClick={() => this.openPot(this.state.cards1.id)} src={"https://www.savezbezimena.eu/wp-content/uploads/2021/07/KARTA_2_854e1555d1.jpg"} alt="slika" />
                            </div>
                            <div className="carousel-item">
                                <img className="slika-naslovna" onClick={() => this.openPot(this.state.cards2.id)} src={"https://www.savezbezimena.eu/wp-content/uploads/2021/07/KARTA_3_40969f2c0b.jpg"} alt="slika" />
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="container-fluid blue">
                    <div className="container">
                        <h3 className="naslov-naslovna">{this.state.oneNewsTitle.rendered}</h3>
                        <p className="naslovni-gornji-text"dangerouslySetInnerHTML={{__html: this.state.oneNewsText.rendered}}/>
                    </div>
                </div>
                <div className="container">
                    <div class="row card-fix">
                    {this.state.zadnjeTrI.map(card => (
                        <div class="col-sm-12 col-md-6 col-lg-4 card-border">
                            <div class="card" style={{ width: '18rem', height: '10rem' }}>
                                <div class="card-body">
                                    <h5 class="card-title">{card.title.rendered}</h5>
                                    <button class="btn btn-outline-primary" onClick={() => this.openCard1(card.id)}>Vidi više</button>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    
                    <p className="vidi-ostalo" onClick={() => this.openNews()}>Vidi ostale vijesti <i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i></p>
                </div>
                <div className="container-fluid yellow">
                    <div className="container">
                        <p className="naslovni-donji-text">Savez bez imena pokret je za više srca u našem svijetu. Osnovao ga je Phil Bosmans (1922−2012). Naš moto: »Na ovom svijetu ima više srca - mog vlastitog!« Udruga bez imena širi poruku srca i podržava projekte socijalne pomoći ljudima u nevolji širom svijeta. Svoj rad financiramo donacijama, prihodima od prodaje i dobrovoljnim radom.  Zabavite se pregledavajući našu web stranicu!</p>
                    </div>
                </div>
                <div className="container img-text-main">
                    <div className="div1"><img src={img} alt="slika"></img></div>
                    <div className="div2"><Link to="/poticajneKarte"><button class="btn btn-outline-primary">Pogledaj ostale poticajne karte</button></Link></div>

                </div>
            </div>
        )
    }
}
