import React from 'react';
import axios from 'axios';

export default class PoticajnaKarta extends React.Component {
    state = {
        cards: [],
        error: null,
        id: this.props.match.params.id,
        slika: [],
        media: [],
        title: []
    };
    componentDidMount = async () => {
        if (this.state.id) {
            try {
                const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/' + this.state.id);
                this.setState({
                    cards: response.data.content,
                    title: response.data.title,
                    media: response.data.featured_media
                });
            } catch (error) {
                this.setState({ error });
            }
        }
        this.findImg();
    };
    findImg = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/media/' + this.state.media);
            this.setState({
                slika: response.data.source_url
            });
        } catch (error) {
            this.setState({ error });
        }
    }

    render() {
        return (
            <>
                <div className="container booms">
                    <h2 className="big-title">{this.state.cards.title}</h2>
                    <div>
                        <img className="pot-img" src={this.state.slika} alt="Card" />
                    </div>
                    <div>
                        <p className="padding-text ptts" dangerouslySetInnerHTML={{ __html: this.state.cards.rendered }} />
                    </div>
                </div>

            </>
        )
    }
}
