import './App.css';
import {Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Poruke from './components/philBosmans/Poruke';
import Knjige from './components/philBosmans/Knjige'
import k13 from './components/savezBezImena/K13';
import PoticajneKarte from './components/PoticajneKarte';
import stoJeSBZ from './components/savezBezImena/stoJeSBZ';
import KakoJeNastao from './components/savezBezImena/KakoJeNastao';
import KakoJeSavezOrganiziran from './components/savezBezImena/KakoJeSavezOrganiziran';
import KakoSavezDjeluje from './components/savezBezImena/KakoSavezDjeluje';
import IspuniSrceMirom from './components/duhovnostSaSrcem/IspuniSrceMirom';
import Multimedija from './components/duhovnostSaSrcem/Multimedija';
import ZivotDjelo from './components/philBosmans/ZivotDjelo';
import PoticajnaKarta from './components/PoticajnaKarta';
import Vijesti from './components/Vijesti';
import JednaVijest from './components/JednaVijest';
import Svjedocanstva from './components/philBosmans/Svjedocanstva';
import PravilaPrivatnosti from './components/PravilaPrivatnosti';
import IspuniSrceSvi from './components/duhovnostSaSrcem/IspuniSrceSvi';
import ViseVj from './components/duhovnostSaSrcem/ViseVj';

function App() {
  return (
    <>
      <Header/>
      <Navbar/>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/poruke" component={Poruke}></Route>
        <Route path="/knjige" component={Knjige}></Route>
        <Route path="/k13" component={k13}></Route>
        <Route path="/poticajnekarte" component={PoticajneKarte}></Route>
        <Route path="/stoJeSavezBezImena" component={stoJeSBZ}></Route>
        <Route path="/kakoJeNastao" component={KakoJeNastao}></Route>
        <Route path="/kakoJeOrganiziran" component={KakoJeSavezOrganiziran}></Route>
        <Route path="/kakoSavezDjeluje" component={KakoSavezDjeluje}></Route>
        <Route path="/ispuniSrceMirom" component={IspuniSrceMirom}></Route>
        <Route path="/multimedija" component={Multimedija}></Route>
        <Route path="/zivotDjelo" component={ZivotDjelo}></Route>
        <Route path="/jednaKarta/:id" component={PoticajnaKarta}></Route>
        <Route path="/vijesti" component={Vijesti}></Route>
        <Route path="/jednaVijest/:id" component={JednaVijest}></Route>
        <Route path="/svjedocanstva" component={Svjedocanstva}></Route>
        <Route path="/pravilaPrivatnosti" component={PravilaPrivatnosti}></Route>
        <Route path="/ispuniSrceSvi" component={IspuniSrceSvi}></Route>
        <Route path="/ispuni-srce-miroms/:id" component={ViseVj}></Route>
      </Switch>
      <Footer/>
    </>
  );
}

export default App;
