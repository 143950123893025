import React from 'react'
import img from '../../img/savezBezImena.png'
import axios from 'axios'

export default class Poruke extends React.Component {
    state = {
        cards: [],
        error: null,
    };
    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=14');
            this.setState({ cards: response.data });
        } catch (error) {
            this.setState({ error });
        }
    };
    render(){
    return (
        <div className="main-div">
            <div className="container-fluid boblur">
                <div className="container img-text-main background-bosmans">
                    <h1 className="title-img-align">svi su ljudi moji prijatelji</h1>
                    <img className="bos-slika2" src={img} alt="img" />
                </div>
            </div>
            <div className="container">
                <h2 className="title-of-col">
                    Što je Savez bez imena?
                </h2>
                {this.state.cards.map(card => (
                    <p className="padding-text" dangerouslySetInnerHTML={{__html: card.content.rendered}}/>
                ))}
            </div>
        </div>
    )}
}
