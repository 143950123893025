import React from 'react';
import axios from 'axios';

export default class PoticajnaKarta extends React.Component {
    state = {
        cards: [],
        error: null,
        title: [],
        id: this.props.match.params.id
    };
    componentDidMount = async () => {
        if (this.state.id) {
            try {
                const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts/' + this.state.id);
                this.setState({
                    title: response.data.title,
                    cards: response.data.content,
                });
            } catch (error) {
                this.setState({ error });
            }
        }
    };

    render() {
        console.log(this.state.cards.rendered)
        return (
            <>
                <div className="container booms">
                    <h3 className="title-of-col"dangerouslySetInnerHTML={{ __html: this.state.title.rendered }} />
                    <div>
                        <p className="padding-text" dangerouslySetInnerHTML={{ __html: this.state.cards.rendered }} />
                    </div>
                </div>

            </>
        )
    }
}
