import React from 'react';
import axios from 'axios';

export default class PoticajneKarte extends React.Component {
    state = {
        cards: [],
        error: null,
    };

    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=3');
            this.setState({ 
                cards: response.data,
            });
        } catch (error) {
            this.setState({ error });
        }
    };
    openCard = (id) => {
        this.props.history.push("/jednaVijest/" + id)
    }

    render() {
        const { error } = this.state;
        if (error) {
            return <div>An error occured: {error.message}</div>;
        }
        return (
            <>
                <div className="card container mt-2 mb-5 p-3 card-news">
                        <div className="row mt-4">
                        {this.state.cards.map(cards => (
                            <div className="style-cards">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 id="readMore" onClick={() => this.openCard(cards.id)}dangerouslySetInnerHTML={{__html: cards.title.rendered}}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p dangerouslySetInnerHTML={{__html: cards.excerpt.rendered}}/>
                                    </div>
                                </div>
                                <div className="row like-unlike">
                                <button class="btn btn-outline-primary" onClick={() => this.openCard(cards.id)}>Vidi više</button>
            
                                </div>
                            </div>
                            </div>
                              ))}
                        </div>  
                   </div>  
            </>
        )
    }
}
