import React from 'react'
import axios from 'axios'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import img from '../../img/biblija.jpg'


export default class Poruke extends React.Component {
    state = {
        cards: [],
        vid: [],
        error: null,
    };
    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=6');
            const response1 = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=5');
            this.setState({
                cards: response.data,
                vid: response1.data
            });
        } catch (error) {
            this.setState({ error });
        }
    };
    render() {
        for (var element of this.state.vid) {
            console.log(element)
        }
        return (
            <div className="main-div">
                 <div className="container-fluid cards-header-b">
                <img className="slika-naslovna sslika"src={img} alt="slika"/>
            </div>
            
                <div className="container audio-player">
                <h2 className="title-of-col">
                   Audio i video zapisi
                </h2>
                    {this.state.cards.map(card => (
                        <>
                            <div>
                            <div className="video-multi" dangerouslySetInnerHTML={{__html: card.content.rendered}}/>
                                        <h3 className="video-a-name">{card.title.rendered}</h3>
                            </div>
                        </>
                    ))}
                    <div className="container ">
                        <div class="row card-fix">
                            {this.state.vid.map(card => (
                                <div class="col-sm-6 col-md-6 card-border">
                                    <div className="vid-text-main vid-text">
                                        <div className="video-multi" dangerouslySetInnerHTML={{__html: card.content.rendered}}/>
                                        <h3 className="video-a-name">{card.title.rendered}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
