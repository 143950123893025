import React from 'react'
import img from '../img/logo.png';
import {Link} from 'react-router-dom'

export default function Footer() {
    const opn = () => {
        window.open("https://www.helioztechnologies.com/")
    }
    return (
        <div className="container-fluid footer-big">
            <div className="container">
                <div className="footer-text-logo">
                    <h2 className="footer-text-main">
                        Više srca - više života
                    </h2>
                    <div className="footer-img">
                        <img className="footer-logo" src={img} alt="logo"/>
                    </div>
                </div>
                <div className="footer-rest">
                    <h3 className="footer-text-name">Savez bez imena</h3>
                    <div className="footer-info">
                    <p className="footer-address">Osječka 125, 35000 Slavonski Brod</p>
                    <p className="flink"><a className="footer-mail-href" href="https://www.facebook.com/Savez-bez-imena-102085379837466">Facebook</a></p>
                    <p className="footer-mail">E-mail: <a className="footer-mail-href" href="mailto:info@savezbezimena.eu">info@savezbezimena.eu</a></p>
                    
                    </div>
                    <p className="footer-copy">Copyright &copy; 2021 Savez bez imena | Sva prava pridržana | <Link to="/pravilaPrivatnosti"><b>Pravila privatnosti</b></Link> | Design and Development by <button class="buttonOpen" onClick={opn}><b>Helioz Technologies</b></button></p>
                </div>            
            </div>
        </div>
    )
}
