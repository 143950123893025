import React from 'react'

export default function PravilaPrivatnosti() {
    const opn = () => {
        window.open("https://eur-lex.europa.eu/legal-content/HR/TXT/?uri=celex%3A32016R0679")
    }
    return (
        <div className="container top-move">
            <p>Savez bez imena je vlasnik web stranice www.savezbezimena.eu (dalje u tekstu: web stranica).  Ova Pravila privatnosti opisuju vrstu informacija koje možemo prikupiti od Vas kada posjetite našu web stranicu te kako se isti obrađuju i koriste. <br />
                Naša praksa i Pravila privatnosti usklađeni su sa  Općom uredbom o zaštiti podataka (GDPR), o kojoj možete više pročitati<button class="buttonOpen" onClick={opn}><b>ovdje.</b></button> <br />
                Molimo vas da ova Pravila privatnosti pročitate pažljivo kako biste saznali kako skupljamo, štitimo ili na bilo koji drugi način obrađujemo i koristimo vaše osobne podatke.</p>
            <h3>Koje informacije prikupljamo o vama i kako ih koristimo?</h3>
            <p>Našu web stranicu možete posjetiti bez otkrivanja osobnih podataka o sebi. Ne prikupljamo osobne podatke o vama (kao što su vaše ime i pojedinosti o kontaktu) kada posjetite ovu web stranicu, osim ako dobrovoljno ne pošaljete podatke pomoću jednog od dostupnih obrazaca za određenu svrhu (kontakt obrazac i slično) i time date vašu privolu na prikupljanje i korištenje vaših osobnih podataka u navedene svrhe. Svi osobni podaci koriste se isključivo u svrhe radi koje su prikupljeni i za koje je dana privola. Prikupljene podatke nećemo ni na koji način učiniti dostupnima neovlaštenim trećim osobama, osim u zakonom propisanu svrhu. Svi podaci se brišu nakon prestanka svrhe za koju su prikupljeni, odnosno prestankom ugovornog odnosa, a najkasnije po isteku svih zakonskih obveza povezanih s čuvanjem osobnih podataka.</p>
            <h3>Informacije koje se automatski prikupljaju na našoj web stranici</h3>
            <p>Određene informacije i podaci mogu se automatski prikupljati putem naših Internet servera ili korištenjem „kolačića“.
                Primjeri informacija o Vašem korištenju stranice uključuju: najposjećenije i najgledanije stranice i poveznice na našoj web stranici, vrijeme provedeno na stranici, najpopularnije ključne riječi koji korisnike vode do naše stranice, vaša IP adresa, informacije koje se skupljaju putem kolačića, podaci vašeg uređaja poput postavki hardwarea, sistemske aktivnosti, vrste pretraživača itd. <br />
                Podatke dobivene o vama koristimo u jednu ili više navedenih svrha: <br />
                •	Radi personalizacije Vašeg korisničkog iskustva (prikupljene informacije nam pomažu da bolje odgovorimo na Vaše individualne potrebe); <br />
                •	Radi poboljšanja naše web stranice (trajno nastojimo poboljšati našu web stranicu na temelju povratnih informacija koje dobivamo od naših posjetitelja). <br />
            </p>
            <h3>Uporaba kolačića</h3>
            <p>Na internetskim stranicama www.savezbezimena.eu primjenjuju se tzv. kolačići (cookies) – tekstualne datoteke koje na računalo korisnika smještaju internetski preglednik kojim se korisnik koristi.
                Datoteke nastaju kada preglednik na uređaju korisnika učita posjećeno mrežno odredište koje potom šalje podatke pregledniku te izrađuje tekstualnu datoteku (kolačić). Preglednik dohvaća i šalje datoteku na poslužitelja mrežnog odredišta (mjesta, stranice) prilikom povratka korisnika na njega.
                Kolačići se upotrebljavaju za funkcioniranje svih značajki internetskih stranica i bolje korisničko iskustvo, a mogu biti privremeni (pohranjuju se samo tijekom posjeta internetskim stranicama) ili trajni (na računalu korisnika ostaju pohranjeni i nakon posjeta).
                Kolačićima trećih strana www.savezbezimena.eu se koristi za dobivanje statističkih podataka o posjećenosti i načinu upotrebe naših internetskih stranica. Podaci koji se prikupljaju uključuju IP adresu korisnika, podatke o pregledniku, jeziku, operativnom sustavu i druge standardne podatke koji se prikupljaju i analiziraju isključivo u anonimnom i masovnom obliku.
                Stranice www.savezbezimena.eu služe se statistikom Google Analytics. Podatke o tome kako se korisnici koriste našom internetskom stranicom povremeno možemo prikupljati koristeći se i drugim alatima sličnima usluzi Google Analytics.
            </p>
            <h3>Zaštita vaših podataka</h3>
            <p>Kako bismo zaštitili osobne podatke koje šaljete preko ove web stranice, koristimo fizičke, tehničke i organizacijske mjere sigurnosti. Neprestano nadograđujemo i testiramo našu sigurnosnu tehnologiju. Ograničavamo pristup Vašim osobnim podacima samo onim zaposlenicima koji trebaju znati te informacije kako bi Vam pružili neke koristi ili usluge. Osim toga, educiramo naše zaposlenike o važnosti povjerljivosti podataka te čuvanju privatnosti i zaštiti Vaših podataka.</p>
            <h3>Pristup i ažuriranje vaših osobnih podataka</h3>
            <p>Naš korisnik u svakom trenutku ima sljedeća prava: <br />
                •	pravo na pristup podacima i uvid u podatke<br />
                •	pravo na informiranost o obradi osobnih podataka<br />
                •	pravo na prenosivost podataka<br />
                •	pravo na povlačenje privole<br />
                •	pravo na podnošenje prigovora<br />
                •	pravo na ispravak i promjenu osobnih podataka u slučaju ako su podaci nepotpuni ili netočni<br />
                •	pravo na brisanje u slučajevima kao što su prestanak svrhe obrade, povlačenje privole ili u slučaju podnošenja prigovora<br />
                Ukoliko želite ostvariti neko od navedenih prava, kontaktirajte nas na email: info@savezbezimena.eu. Također nas možete kontaktirati i telefonom koji je naveden na web stranici ili putem slanja pošte na našu adresu koja je navedena na web stranici.
            </p>
            <h3>Obavješćivanje o povredi osobnih podataka</h3>
            <p>U slučaju povrede osobnih podataka obavijestit ćemo Vas i nadležno nadzorno tijelo  porukom e-pošte u roku 72 sata o razmjerima povrede, obuhvaćenim podacima, možebitnom utjecaju na naše usluge i našim planiranim mjerama za osiguranje podataka i ograničenje bilo kakvih štetnih učinaka po pojedince.<br />
                Obavijest o povredi Vam nećemo poslati u slučaju: <br />
                •	ako postoje tehničke i organizacijske mjere zaštite (poput enkripcije) koje su primijenjene na osobne podatke pogođene povredom osobnih podataka, koje te podatke čine nerazumljiva bilo kojoj osobi koja im nije ovlaštena pristupiti;<br />
                •	ako smo poduzeli naknadne mjere kojima se osigurava da više nije vjerojatno da će doći do visokog rizika za prava i slobode pojedinaca;<br />
                •	ako bi to iziskivalo nerazmjeran napor (u tom slučaju ćemo Vas obavijestiti putem sredstava javnog obavješćivanja ili slične jednako djelotvorne mjere).<br />
                Povreda osobnih podataka znači povredu sigurnosti koja vodi do nenamjernog ili nezakonitog uništenja, gubitka, izmjene, neautoriziranog otkrivanja ili pristupa osobnim podacima koji se prenose, čuvaju i obrađuju u vezi s nuđenjem naših usluga.<br />
            </p>
            <h3>Vaša privola</h3>
            <p>Korištenjem ove web stranice dajete privolu na ovu politiku privatnosti.
                Ova politika privatnosti je zadnji put izmijenjena u lipnju 2021. godine.
            </p>
        </div>
    )
}
