import React from 'react'
import img from '../../img/phil.png'
import axios from 'axios'


export default class Poruke extends React.Component {
    state = {
        cards: [],
        error: null,
    };
    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=12');
            this.setState({ cards: response.data });
        } catch (error) {
            this.setState({ error });
        }
    };
    render(){
    return (
        <div className="main-div">
            <div className="container-fluid bosblur">
                <div className="container img-text-main background-bosmans">
                    <h1 className="title-img-align">svako srce <br/> treba dom</h1>
                    <img className="bos-slika" src={img} alt="img" />
                </div>
            </div>
            <div className="container">
                <h2 className="title-of-col">
                    Phil Bosmans i njegova poruka
                </h2>
                {this.state.cards.map(card => (
                    <p className="padding-text" dangerouslySetInnerHTML={{__html: card.content.rendered}}/>
                ))}
            </div>
        </div>
    )}
}
