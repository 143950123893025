import React from 'react';
import axios from 'axios';

export default class PoticajneKarte extends React.Component {
    state = {
        cards: [],
        error: null,
        slika: []
    };

    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=4');
            this.setState({ 
                cards: response.data,
            });
        } catch (error) {
            this.setState({ error });
        }
    };
    openCard = (id) => {
        this.props.history.push("/ispuni-srce-miroms/" + id)
    }
    

    render() {
        const { error } = this.state;
        if (error) {
            return <div>An error occured: {error.message}</div>;
        }
        return (
            <>
                <div className="card container mt-2 mb-5 p-3 card-news">
                        <div className="row mt-4">
                        {this.state.cards.map(cards => (
                            <div class="card w-100">
                            <div class="card-body">
                              <h5 class="card-title">{cards.title.rendered}</h5>
                              <p class="card-text"dangerouslySetInnerHTML={{__html: cards.excerpt.rendered}}/>
                              <button class="btn btn-outline-primary" onClick={() => this.openCard(cards.id)}>Vidi više</button>
                            </div>
                          </div>
                              ))}
                        </div> 
                        
                   </div>  
            </>
        )
    }
}
