
import React, { useState } from "react";
import {Link} from 'react-router-dom'

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="container">
          <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item all-list" ><Link to="/">POČETNA STRANICA</Link></li>
                    <li className="nav-item dropdown all-list">
                        <a className="nav-link dropdown-toggle" href="#a" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            PHIL BOSMANS
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/poruke" className="dropdown-item" >PORUKE</Link>
                            <Link to="/zivotDjelo" className="dropdown-item" >P.B.-ŽIVOT I DJELO</Link>
                            <Link to="/knjige" className="dropdown-item">P.B.-KNJIGE</Link>
                            <Link to="/svjedocanstva" className="dropdown-item">P.B.-SVJEDOČANSTVA</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown all-list">
                        <a className="nav-link dropdown-toggle" href="#a" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            SAVEZ BEZ IMENA
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/stoJeSavezBezImena" className="dropdown-item">Što je Savez bez Imena?</Link>
                            <Link to="/k13" className="dropdown-item">K-13</Link>
                            <Link to="/kakoJeNastao" className="dropdown-item">Kako je nastao Savez?</Link>
                            <Link to="/kakoSavezDjeluje" className="dropdown-item">Kako djeluje Savez?</Link>
                            <Link to="/kakoJeOrganiziran" className="dropdown-item">Kako je organiziran Savez?</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown all-list">
                        <a className="nav-link dropdown-toggle" href="#a" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            DUHOVNOST SA SRCEM
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link to="/ispuniSrceMirom" className="dropdown-item">ISPUNI SRCE MIROM</Link>
                            <Link to="/multimedija" className="dropdown-item">MULTIMEDIJA</Link>
                        </div>
                    </li>
                    <li className="all-list"><Link to="/poticajneKarte">POTICAJNE KARTE</Link></li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;



