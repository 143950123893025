import React from 'react'
import img from '../../img/biblija.jpg'
import axios from 'axios'



export default class Poruke extends React.Component {
    state = {
        svi: [],
        naslov: [],
        tekst: [],
        error: null,
    };
    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=4');
            this.setState({
                svi: response.data
            });
        } catch (error) {
            this.setState({ error });
        }
        this.onp();
    };

    onp = async () => {
        const num = this.state.svi.length - 1;
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=4');
            this.setState({
                naslov: response.data[num].title.rendered,
                tekst: response.data[num].content.rendered
            });
        } catch (error) {
            this.setState({ error });
        }
    }
    openCat = () => {
        this.props.history.push("/ispuniSrceSvi")
    }

    render() {
        console.log(this.state.naslov)
        return (
            <div className="main-div">
                <div className="container-fluid cards-header-b">
                    <img className="slika-naslovna sslika" src={img} alt="slika" />
                </div>
                <div className="container">
                    <h3 className="title-of-col">{this.state.naslov}</h3>
                    <p className="padding-text" dangerouslySetInnerHTML={{__html: this.state.tekst}}/>

                </div>
                <div className="container">
                    <p className="vidi-ostalo" onClick={() => this.openCat()}>Vidi ostale tekstove <i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i></p>
                </div>
            </div>
        )
    }
}
