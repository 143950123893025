import React from 'react';
import axios from 'axios';
import img from '../img/karte-naslovna.jpg';


export default class PoticajneKarte extends React.Component {
    state = {
        cards: [],
        error: null,
        slika: [],
        media: []
    };

    componentDidMount = async () => {
        try {
            const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/posts?categories=2');
            this.setState({ cards: response.data});
         
        } catch (error) {
            this.setState({ error });
        }
        this.openImg()
    };
    openImg = async () => {
        try {
            for (var v of this.state.cards){
                const response = await axios.get('https://www.savezbezimena.eu/wp-json/wp/v2/media/' + v.featured_media);
              v.slika = [];
                v.slika.push(response.data.source_url);
            }
        } catch (error) {
            this.setState({ error });
        }
    }
   
    openCard = (id) => {
        this.props.history.push("/jednaKarta/" + id)
    }
    render() {
        console.log(this.state.media)
        const { error } = this.state;
        if (error) {
            return <div>An error occured: {error.message}</div>;
        }
        if(true) {
        return (
            <div className="main-div">
            <div className="container-fluid cards-header">
                <img className="slika-naslovna" src={img} alt="slika"/>
            </div>
            <div className="container-fluid blue">
                    <div className="container">
                        <h3 className="naslov-naslovna">Poticajne karte</h3>
                        <p className="naslovni-gornji-text">Phil Bosmans započeo je inovativne socijalne projekte. Istodobno se uvijek obraćao pojedincu, jer „kultura srca“ započinje sa svakim pojedincem. Kratki tekstovi koji daju hrabrost, utjehu, otvaraju vam oči: unajmio je vlastitu službu za telefonske poruke i dao tiskati kartice. Danas Savez bez imena nastavlja zadatak ispisivanja "poruke srca" u kratkim tekstovima i širenja na Internetu putem poticajnih karti.</p>
                    </div>
                </div>
            <div className="container ">
                <div class="row card-fix">
                    {this.state.cards.map((card, index) => (
                        <div class="col-sm-6 col-md-6 card-border">
                            <div class="card">
                            <div dangerouslySetInnerHTML={{ __html: card.excerpt.rendered }} />
                                <div class="card-body">
                                    <h5 class="card-title">{card.title.rendered}</h5>
                                    <button class="btn btn-outline-primary" onClick={() => this.openCard(card.id)}>Vidi više</button>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
            </div>
            </div>
        )
    }}
}
