import React from 'react'
import Logo from '../img/logo.png'
import {Link} from 'react-router-dom'

export default function Header() {
    return (
        <div className="container">
            <div className="header">
                <div className="carousel slide slidess" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <Link to="/"><p className="header-line big-title-upper">Savez bez imena</p></Link>
                        </div>
                        <div className="carousel-item">
                            <Link to="/"><p className="header-line">Više srca - više života</p></Link>
                        </div>
                        <div className="carousel-item">
                            <Link to="/"><p id="last" className="header-line h-line1">Za čovječniji svijet i sretnijeg čovjeka</p></Link>
                        </div>
                    </div>
                </div>
                <div className="logo-head">
                    <Link to="/"><img src={Logo} alt="img"></img></Link>
                </div>
            </div>
           
        </div>
    )
}
